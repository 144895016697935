import React from 'react';
import { Link } from 'gatsby';
import footerStyles from '../styles/footer.module.css';

const Footer = () => {
  return (
    <footer>
      <div className={footerStyles.container}>
        <a className={footerStyles.copyright} href={'https://listolabs.com/'}>
          © {new Date().getFullYear()} Listo Labs
        </a>
        <span className={footerStyles.spacer}>·</span>
        <Link to={'/support'}>Support</Link>
        <span className={footerStyles.spacer}>·</span>
        <Link to={'/privacy'}>Privacy</Link>
        <span className={footerStyles.spacer}>·</span>
        <Link to={'/terms'}>Terms</Link>
      </div>
    </footer>
  );
};

export default Footer;
